/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'MainNavigation',
        import: () => import(
        /* webpackChunkName: "MainNavigation" */
        '@stories/Widgets/Navigation/MainNavigation/MainNavigation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'Footer',
        import: () => import(/* webpackChunkName: "Footer" */ '@stories/Widgets/Footers/Footer/Footer'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ActivityCards',
        import: () => import(/* webpackChunkName: "ActivityCards" */ '@stories/Widgets/Content/ActivityCards/ActivityCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerBanner',
        import: () => import(/* webpackChunkName: "AuthorisedRetailerBanner" */ '@stories/Widgets/Banners/AuthorisedRetailBanner/AuthorisedRetailerBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerFirstTimeVideo',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerFirstTimeVideo" */ '@stories/Widgets/Content/AuthorisedRetailerFirstTimeVideo/AuthorisedRetailerFirstTimeVideo'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerVideoDetail',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerVideoDetail" */ '@stories/Widgets/Media/AuthorisedRetailerVideoDetail/AuthorisedRetailerVideoDetail'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerVideoCategory',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerVideoCategory" */ '@stories/Widgets/Media/AuthorisedRetailerVideoCategory/AuthorisedRetailerVideoCategory'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerVideoLibrary',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerVideoLibrary" */ '@stories/Widgets/Media/AuthorisedRetailerVideoLibrary/AuthorisedRetailerVideoLibrary'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerProductDetailMediaSliders',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerProductDetailMediaSliders" */ '@stories/Widgets/Media/AuthorisedRetailerProductDetailMediaSliders/AuthorisedRetailerProductDetailMediaSliders'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerVideoSearchResults',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerVideoSearchResults" */ '@stories/Widgets/Media/AuthorisedRetailerVideoSearchResults/AuthorisedRetailerVideoSearchResults'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FAQ',
        import: () => import(/* webpackChunkName: "FAQ" */ '@stories/Widgets/Content/FAQ/FAQ'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Gallery',
        import: () => import(/* webpackChunkName: "Gallery" */ '@stories/Widgets/Media/Gallery/Gallery'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ContactForm',
        import: () => import(/* webpackChunkName: "ContactForm" */ '@stories/Widgets/Forms/ContactForm/ContactForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PopupManager',
        import: () => import(/* webpackChunkName: "PopupManager" */ '@stories/Widgets/Snackbars/PopupManager/PopupManager'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'BrushlessHex',
        import: () => import(/* webpackChunkName: "BrushlessHex" */ '@stories/Widgets/Media/BrushlessHex/BrushlessHex'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusHPProductFocus',
        import: () => import(/* webpackChunkName: "OnePlusHPProductFocus" */ '@stories/Widgets/Content/OnePlusHPProductFocus/OnePlusHPProductFocus'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusHPProductCarousel',
        import: () => import(/* webpackChunkName: "OnePlusHPProductCarousel" */ '@stories/Widgets/Media/OnePlusHPProductCarousel/OnePlusHPProductCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'OnePlusHPBanner',
        import: () => import(/* webpackChunkName: "OnePlusHPBanner" */ '@stories/Widgets/Banners/OnePlusHPBanner/OnePlusHPBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusHPCentreText',
        import: () => import(/* webpackChunkName: "OnePlusHPCentreText" */ '@stories/Widgets/Content/OnePlusHPCentreText/OnePlusHPCentreText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusHPIntro',
        import: () => import(/* webpackChunkName: "OnePlusHPIntro" */ '@stories/Widgets/Content/OnePlusHPIntro/OnePlusHPIntro'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusIntro',
        import: () => import(/* webpackChunkName: "OnePlusIntro" */ '@stories/Widgets/Content/OnePlusIntro/OnePlusIntro'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusHPSignpost',
        import: () => import(/* webpackChunkName: "OnePlusHPSignpost" */ '@stories/Widgets/Content/OnePlusHPSignpost/OnePlusHPSignpost'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusIcons',
        import: () => import(/* webpackChunkName: "OnePlusIcons" */ '@stories/Widgets/Content/OnePlusIcons/OnePlusIcons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusSignpost',
        import: () => import(/* webpackChunkName: "OnePlusSignpost" */ '@stories/Widgets/Content/OnePlusSignpost/OnePlusSignpost'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusSplitCards',
        import: () => import(/* webpackChunkName: "OnePlusSplitCards" */ '@stories/Widgets/Content/OnePlusSplitCards/OnePlusSplitCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BannerWithContent',
        import: () => import(/* webpackChunkName: "BannerWithContent" */ '@stories/Widgets/Banners/BannerWithContent/BannerWithContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusV2Banner',
        import: () => import(/* webpackChunkName: "OnePlusV2Banner" */ '@stories/Widgets/Banners/OnePlusV2Banner/OnePlusV2Banner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusImageWithText',
        import: () => import(/* webpackChunkName: "OnePlusSignpost" */ '@stories/Widgets/Content/OnePlusImageWithText/OnePlusImageWithText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusJoin',
        import: () => import(/* webpackChunkName: "OnePlusJoin" */ '@stories/Widgets/Content/OnePlusJoin/OnePlusJoin'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RuntimeGrid',
        import: () => import(/* webpackChunkName: "RuntimeGrid" */ '@stories/Widgets/Content/RuntimeGrid/RuntimeGrid'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusHPVideo',
        import: () => import(/* webpackChunkName: "OnePlusHPVideo" */ '@stories/Widgets/Media/OnePlusHPVideo/OnePlusHPVideo'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'GenericCardSection',
        import: () => import(/* webpackChunkName: "GenericCardSection" */ '@stories/Widgets/Content/GenericCardSection/GenericCardSection'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'InnovationCarousel',
        import: () => import(/* webpackChunkName: "InnovationCarousel" */ '@stories/Widgets/Content/InnovationCarousel/InnovationCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'SystemCallouts',
        import: () => import(/* webpackChunkName: "SystemCallouts" */ '@stories/Widgets/Content/SystemCallouts/SystemCallouts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusHPPopup',
        import: () => import(/* webpackChunkName: "OnePlusHPPopup" */ '@stories/Widgets/Content/OnePlusHPPopup/OnePlusHPPopup'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusHPCallout',
        import: () => import(/* webpackChunkName: "OnePlusHPCallout" */ '@stories/Widgets/Content/OnePlusHPCallout/OnePlusHPCallout'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TextWithBorder',
        import: () => import(/* webpackChunkName: "TextWithBorder" */ '@stories/Widgets/Content/TextWithBorder/TextWithBorder'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PromotionalBanner',
        import: () => import(/* webpackChunkName: "PromotionalBanner" */ '@stories/Widgets/Content/PromotionalBanner/PromotionalBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TechnologyCarousel',
        import: () => import(/* webpackChunkName: "TechnologyCarousel" */ '@stories/Widgets/Content/TechnologyCarousel/TechnologyCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'EcommHomeBanner',
        import: () => import(/* webpackChunkName: "EcommHomeBanner" */ '@stories/Widgets/Banners/EcommHomeBanner/EcommHomeBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'RecentlyViewedItems',
        import: () => import(/* webpackChunkName: "RecentlyViewedItems" */ '@stories/Widgets/Products/RecentlyViewedItems/RecentlyViewedItems'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'PromotionBanner',
        import: () => import(/* webpackChunkName: "PromotionBanner" */ '@stories/Widgets/Media/PromotionBanner/PromotionBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboyagiHelpCentreBanner',
        import: () => import(/* webpackChunkName: "RoboyagiHelpCentreBanner" */ '@stories/Widgets/Banners/RoboyagiHelpCentreBanner/RoboyagiHelpCentreBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboyagiWarranty',
        import: () => import(/* webpackChunkName: "RoboyagiWarranty" */ '@stories/Widgets/Content/RoboyagiWarranty/RoboyagiWarranty'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SquareSplit5050',
        import: () => import(/* webpackChunkName: "SquareSplit5050" */ '@stories/Widgets/Content/SquareSplit5050/SquareSplit5050'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboyagiWhereToBuy',
        import: () => import(/* webpackChunkName: "RoboyagiWhereToBuy" */ '@stories/Widgets/Content/RoboyagiWhereToBuy/RoboyagiWhereToBuy'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboyagiInstaller',
        import: () => import(/* webpackChunkName: "RoboyagiInstaller" */ '@stories/Widgets/Content/RoboyagiInstaller/RoboyagiInstaller'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ContactUs',
        import: () => import(/* webpackChunkName: "ContactUs" */ '@stories/Widgets/Forms/ContactUs/ContactUs'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ConfigurableContactUs',
        import: () => import(/* webpackChunkName: "ConfigurableContactUs" */ '@stories/Widgets/Forms/ConfigurableContactUs/ConfigurableContactUs'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AddToBasket',
        import: () => import(/* webpackChunkName: "AddToBasket" */ '@stories/Widgets/Ecommerce/AddToBasket/AddToBasket'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AddedToBasket',
        import: () => import(/* webpackChunkName: "AddedToBasket" */ '@stories/Widgets/Ecommerce/AddedToBasket/AddedToBasket'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'Basket',
        import: () => import(/* webpackChunkName: "Basket" */ '@stories/Widgets/Ecommerce/Basket/Basket'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Breadcrumb',
        import: () => import(/* webpackChunkName: "Breadcrumb" */ '@stories/Widgets/Navigation/Breadcrumb/Breadcrumb'),
        config: {
            hydrate: false,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Checkout',
        import: () => import(/* webpackChunkName: "Checkout" */ '@stories/Widgets/Ecommerce/Checkout/Checkout'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductDetails',
        import: () => import(/* webpackChunkName: "ProductDetails" */ '@stories/Widgets/Products/ProductDetails/ProductDetails'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OrderConfirmation',
        import: () => import(/* webpackChunkName: "Confirmation" */ '@stories/Widgets/Ecommerce/Confirmation/Confirmation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'EcommIntro',
        import: () => import(/* webpackChunkName: "EcommIntro" */ '@stories/Widgets/Content/EcommIntro/EcommIntro'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'EcommPromotions',
        import: () => import(/* webpackChunkName: "EcommPromotions" */ '@stories/Widgets/Products/EcommPromotions/EcommPromotions'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'EcommQuickLinks',
        import: () => import(/* webpackChunkName: "EcommQuickLinks" */ '@stories/Widgets/Content/EcommQuickLinks/EcommQuickLinks'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AgentsProductSearch',
        import: () => import(/* webpackChunkName: "AgentsProductSearch" */ '@stories/Widgets/Products/AgentsProductSearch/AgentsProductSearch'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AgentsPortalLogin',
        import: () => import(/* webpackChunkName: "AgentsPortalLogin" */ '@stories/Widgets/Ecommerce/AgentsPortal/AgentsPortalLogin/AgentsPortalLogin'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AgentsPortalPasswordReset',
        import: () => import(
        /* webpackChunkName: "AgentsPortalPasswordReset" */ '@stories/Widgets/Ecommerce/AgentsPortal/AgentsPortalPasswordReset/AgentsPortalPasswordReset'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FeatureList',
        import: () => import(/* webpackChunkName: "FeatureList" */ '@stories/Components/Content/FeatureList/FeatureList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MessageModal',
        import: () => import(/* webpackChunkName: "MessageModal" */ '@stories/Components/Modals/MessageModal/MessageModal'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'AddToBasketSnackbar',
        import: () => import(/* webpackChunkName: "AddToBasketSnackbar" */ '@stories/Widgets/Snackbars/AddToBasketSnackbar/AddToBasketSnackbar'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'TrackOrderForm',
        import: () => import(/* webpackChunkName: "TrackOrderForm" */ '@stories/Widgets/Forms/TrackOrderForm/TrackOrderForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'DownloadInvoiceForm',
        import: () => import(/* webpackChunkName: "DownloadInvoiceForm" */ '@stories/Widgets/Forms/DownloadInvoiceForm/DownloadInvoiceForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'QuickLinks',
        import: () => import(/* webpackChunkName: "QuickLinks" */ '@stories/Widgets/Navigation/QuickLinks/QuickLinks'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'GridStyleTag',
        import: () => import(/* webpackChunkName: "GridStyleTag" */ '@stories/Widgets/Misc/GridStyleTag/GridStyleTag'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PageTitle',
        import: () => import(/* webpackChunkName: "PageTitle" */ '@stories/Widgets/Content/PageTitle/PageTitle'),
        config: {
            hydrate: false,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RichTextArea',
        import: () => import(/* webpackChunkName: "RichTextArea" */ '@stories/Widgets/Content/RichTextArea/RichTextArea'),
        config: {
            hydrate: false,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PaymentFailed',
        import: () => import(/* webpackChunkName: "PaymentFailed" */ '@stories/Widgets/Ecommerce/PaymentFailed/PaymentFailed'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PaymentPending',
        import: () => import(/* webpackChunkName: "PaymentPending" */ '@stories/Widgets/Ecommerce/PaymentPending/PaymentPending'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboyagiFAQ',
        import: () => import(/* webpackChunkName: "RoboyagiFAQ" */ '@stories/Widgets/Content/RoboyagiFAQ/RoboyagiFAQ'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductDetailSeoText',
        import: () => import(/* webpackChunkName: "ProductDetailSEOText" */ '@stories/Widgets/ProductDetail/ProductDetailSEOText/ProductDetailSEOText'),
        config: {
            hydrate: false,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Video',
        import: () => import(/* webpackChunkName: "Video" */ '@stories/Widgets/Media/Video/Video'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailSignpost',
        import: () => import(/* webpackChunkName: "AuthorisedRetailSignpost" */ '@stories/Widgets/Media/AuthorisedRetailSignpost/AuthorisedRetailSignpost'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SplitSkewBanner',
        import: () => import(/* webpackChunkName: "SplitSkewBanner" */ '@stories/Widgets/Banners/SplitSkewBanner/SplitSkewBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TabbedContent',
        import: () => import(/* webpackChunkName: "TabbedContent" */ '@stories/Widgets/Content/TabbedContent/TabbedContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ContentCards',
        import: () => import(/* webpackChunkName: "ContentCards" */ '@stories/Widgets/Content/ContentCards/ContentCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TopTips',
        import: () => import(/* webpackChunkName: "TopTips" */ '@stories/Widgets/Content/TopTips/TopTips'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'StickyNavigation',
        import: () => import(/* webpackChunkName: "StickyNavigation" */ '@stories/Widgets/Content/StickyNavigation/StickyNavigation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RegisterForm',
        import: () => import(/* webpackChunkName: "RegisterForm" */ '@stories/Widgets/Forms/RegisterForm/RegisterForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ImageSlider',
        import: () => import(/* webpackChunkName: "ImageSlider" */ '@stories/Widgets/Media/ImageSlider/ImageSlider'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SimpleProductList',
        import: () => import(/* webpackChunkName: "SimpleProductList" */ '@stories/Widgets/Products/SimpleProductList/SimpleProductList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'StickyLeftAside',
        import: () => import(/* webpackChunkName: "StickyLeftAside" */ '@stories/Widgets/Navigation/StickyLeftAside/StickyLeftAside'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'ProductDescription',
        import: () => import(/* webpackChunkName: "ProductDescription" */ '@stories/Widgets/Products/ProductDescription/ProductDescription'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductDetailSpecifications',
        import: () => import(
        /* webpackChunkName: "ProductDetailSpecifications" */ '@stories/Widgets/ProductDetail/ProductDetailSpecifications/ProductDetailSpecifications'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RelatedItems',
        import: () => import(/* webpackChunkName: "RelatedItems" */ '@stories/Widgets/Products/RelatedItems/RelatedItems'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductRuntime',
        import: () => import(/* webpackChunkName: "ProductRuntime" */ '@stories/Widgets/Products/ProductRuntime/ProductRuntime'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductReviews',
        import: () => import(/* webpackChunkName: "ProductReviews" */ '@stories/Widgets/Products/ProductReviews/ProductReviews'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'Download',
        import: () => import(/* webpackChunkName: "Download" */ '@stories/Widgets/Products/Download/Download'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductAwards',
        import: () => import(/* webpackChunkName: "ProductAwards" */ '@stories/Widgets/Products/ProductAwards/ProductAwards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'StickyLeftScrollToTop',
        import: () => import(/* webpackChunkName: "StickyLeftScrollToTop" */ '@stories/Widgets/Navigation/StickyLeftScrollToTop/StickyLeftScrollToTop'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'MoistureMeter',
        import: () => import(/* webpackChunkName: "MoistureMeter" */ '@stories/Widgets/Content/Instruments/MoistureMeter/MoistureMeter'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MoistureMeterElectronics',
        import: () => import(
        /* webpackChunkName: "MoistureMeterElectronics" */ '@stories/Widgets/Content/Instruments/MoistureMeterElectronics/MoistureMeterElectronics'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'InstrumentsCategories',
        import: () => import(/* webpackChunkName: "InstrumentsCategories" */ '@stories/Widgets/Content/Instruments/InstrumentsCategories/InstrumentsCategories'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboyagiContactForm',
        import: () => import(/* webpackChunkName: "RoboyagiContactForm" */ '@stories/Widgets/Forms/RoboyagiContactForm/RoboyagiContactForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AwardsCards',
        import: () => import(/* webpackChunkName: "AwardsCards" */ '@stories/Widgets/Content/AwardsCards/AwardsCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductListing',
        import: () => import(/* webpackChunkName: "ProductListing" */ '@stories/Widgets/Products/ProductListing/ProductListing'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'OnePlusHPImageGrid',
        import: () => import(/* webpackChunkName: "OnePlusHPImageGrid" */ '@stories/Widgets/Media/OnePlusHPImageGrid/OnePlusHPImageGrid'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VideoSteps',
        import: () => import(/* webpackChunkName: "VideoSteps" */ '@stories/Widgets/Media/VideoSteps/VideoSteps'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'InverterCalculator',
        import: () => import('@stories/Widgets/Products/InverterCalculator/InverterCalculator'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'GlobalStyles',
        import: () => import(/* webpackChunkName: "GlobalStyles" */ '@stories/GlobalStyles/GlobalStyles'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'OneTrustOverlay',
        import: () => import(/* webpackChunkName: "OneTrustOverlay" */ '@stories/Widgets/Misc/OneTrustOverlay/OneTrustOverlay'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'CTA',
        import: () => import(/* webpackChunkName: "CTA" */ '@stories/Widgets/Content/CTA/CTA'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CentreText',
        import: () => import(/* webpackChunkName: "CentreText" */ '@stories/Widgets/Content/CentreText/CentreText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MaxPowerIcons',
        import: () => import(/* webpackChunkName: "MaxPowerIcons" */ '@stories/Widgets/Content/MaxPowerIcons/MaxPowerIcons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BrushlessTeaser',
        import: () => import(/* webpackChunkName: "BrushlessTeaser" */ '@stories/Widgets/Content/BrushlessTeaser/BrushlessTeaser'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BannerWithPositionalText',
        import: () => import(/* webpackChunkName: "BannerWithPositionalText" */ '@stories/Widgets/Content/BannerWithPositionalText/BannerWithPositionalText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductPromotionCarousel',
        import: () => import(/* webpackChunkName: "ProductPromotionCarousel" */ '@stories/Widgets/Content/ProductPromotionCarousel/ProductPromotionCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MaxPowerSplitContent',
        import: () => import(/* webpackChunkName: "MaxPowerSplitContent" */ '@stories/Widgets/Content/MaxPowerSplitContent/MaxPowerSplitContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MaxPowerRuntimeTags',
        import: () => import(/* webpackChunkName: "MaxPowerRuntimeTags" */ '@stories/Widgets/Content/MaxPowerRuntimeTags/MaxPowerRuntimeTags'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'IntelliCell',
        import: () => import(/* webpackChunkName: "IntelliCell" */ '@stories/Widgets/Content/IntelliCell/IntelliCell'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'WarrantyCTA',
        import: () => import(/* webpackChunkName: "WarrantyCTA" */ '@stories/Widgets/Content/WarrantyCTA/WarrantyCTA'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VideoBanner',
        import: () => import(/* webpackChunkName: "VideoBanner" */ '@stories/Widgets/Banners/VideoBanner/VideoBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PreferenceCentreForm',
        import: () => import(/* webpackChunkName: "PreferenceCentreForm" */ '@stories/Widgets/Forms/PreferenceCentreForm/PreferenceCentreForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CookiePolicy',
        import: () => import(/* webpackChunkName: "CookiePolicy" */ '@stories/Widgets/Content/CookiePolicy/CookiePolicy'),
        config: {
            hydrate: false,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailLandingBanner',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailLandingBanner" */ '@stories/Widgets/Banners/AuthorisedRetailLandingBanner/AuthorisedRetailLandingBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerIntroductionText',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerIntroductionText" */ '@stories/Widgets/Content/AuthorisedRetailerIntroductionText/AuthorisedRetailerIntroductionText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerSignpostCards',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerSignpostCards" */ '@stories/Widgets/Content/AuthorisedRetailerSignpostCards/AuthorisedRetailerSignpostCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerSignpostButtons',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerSignpostButtons" */ '@stories/Widgets/Content/AuthorisedRetailerSignpostButtons/AuthorisedRetailerSignpostButtons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerIFrame',
        import: () => import(/* webpackChunkName: "AuthorisedRetailerIFrame" */ '@stories/Widgets/Misc/AuthorisedRetailerIFrame/AuthorisedRetailerIFrame'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerSplitMediaSlider',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerSplitMediaSlider" */ '@stories/Widgets/Media/AuthorisedRetailerSplitMediaSlider/AuthorisedRetailerSplitMediaSlider'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerProfileArea',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerProfileArea" */ '@stories/Widgets/Content/AuthorisedRetailerProfileArea/AuthorisedRetailerProfileArea'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MaxPowerBatteries',
        import: () => import(/* webpackChunkName: "MaxPowerBatteries" */ '@stories/Widgets/Content/MaxPowerBatteries/MaxPowerBatteries'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MaxPowerHighEnergy',
        import: () => import(/* webpackChunkName: "MaxPowerHighEnergy" */ '@stories/Widgets/Content/MaxPowerHighEnergy/MaxPowerHighEnergy'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MaxPowerToolRuntime',
        import: () => import(/* webpackChunkName: "MaxPowerToolRuntime" */ '@stories/Widgets/Content/MaxPowerToolRuntime/MaxPowerToolRuntime'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FreeToolModal',
        import: () => import('@stories/Widgets/Products/FreeToolModal/FreeToolModal'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'AuthorisedRetailerBadgeBanner',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerBadgeBanner" */ '@stories/Widgets/Banners/AuthorisedRetailerBadgeBanner/AuthorisedRetailerBadgeBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerResetPasswordForm',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerResetPasswordForm" */ '@stories/Widgets/Forms/AuthorisedRetailer/AuthorisedRetailerResetPasswordForm/AuthorisedRetailerResetPasswordForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerResetMobileForm',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerResetMobileForm" */ '@stories/Widgets/Forms/AuthorisedRetailer/AuthorisedRetailerResetMobileForm/AuthorisedRetailerResetMobileForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'KeyCallouts',
        import: () => import(/* webpackChunkName: "KeyCallouts" */ '@stories/Widgets/Content/KeyCallouts/KeyCallouts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TabbedProductContent',
        import: () => import(/* webpackChunkName: "TabbedProductContent" */ '@stories/Widgets/Content/TabbedProductContent/TabbedProductContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CookieText',
        import: () => import(/* webpackChunkName: "CookieText" */ '@stories/Widgets/Content/CookieText/CookieText'),
        config: {
            hydrate: false,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CaptionVideo',
        import: () => import('@stories/Widgets/Content/CaptionVideo/CaptionVideo'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TripleCards',
        import: () => import('@stories/Widgets/Content/TripleCards/TripleCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusCategories',
        import: () => import('@stories/Widgets/Content/OnePlusCategories/OnePlusCategories'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ScriptManagement',
        import: () => import(/* webpackChunkName: "ScriptManagement" */ '@stories/Widgets/Misc/ScriptManagement/ScriptManagement'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'BrushlessSystemCTA',
        import: () => import('@stories/Widgets/Content/BrushlessSystemCTA/BrushlessSystemCTA'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BrushlessVideoBanner',
        import: () => import('@stories/Widgets/Banners/BrushlessVideoBanner/BrushlessVideoBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BrushlessLandingBanner',
        import: () => import('@stories/Widgets/Banners/BrushlessLandingBanner/BrushlessLandingBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BrushlessTechnology',
        import: () => import('@stories/Widgets/Content/BrushlessTechnology/BrushlessTechnology'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BrushlessCardGrid',
        import: () => import('@stories/Widgets/Content/BrushlessCardGrid/BrushlessCardGrid'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BrushlessStepByStepVideo',
        import: () => import('@stories/Widgets/Media/BrushlessStepByStepVideo/BrushlessStepByStepVideo'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'GetInspiredDetail',
        import: () => import('@stories/Widgets/Content/Blog/GetInspiredDetail/GetInspiredDetail'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'BlogNewsAndListing',
        import: () => import('@stories/Widgets/Content/Blog/BlogAndNewsListing/BlogAndNewsListing'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'ProductTeesterVideo',
        import: () => import(/* webpackChunkName: "ProductTeesterVideo" */ '@stories/Widgets/ProductDetail/ProductTeesterVideo/ProductTeesterVideo'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'FloatingCards',
        import: () => import('@stories/Widgets/Content/FloatingCards/FloatingCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SplitCarousel',
        import: () => import('@stories/Widgets/Content/SplitCarousel/SplitCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ActivitiesCarousel',
        import: () => import('@stories/Widgets/Content/ActivitiesCarousel/ActivitiesCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductLandingProducts',
        import: () => import('@stories/Widgets/Content/ProductLandingProducts/ProductLandingProducts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ImageCollage',
        import: () => import('@stories/Widgets/Media/ImageCollage/ImageCollage'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VideoCarousel',
        import: () => import('@stories/Widgets/Media/VideoCarousel/VideoCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ActivitiesSplitBanner',
        import: () => import('@stories/Widgets/Banners/ActivitiesSplitBanner/ActivitiesSplitBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LightingProductFinder',
        import: () => import('@stories/Widgets/Content/LightingProductFinder/LightingProductFinder'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductFinder',
        import: () => import('@stories/Widgets/Products/ProductFinder/ProductFinder'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'IconGrid',
        import: () => import('@stories/Widgets/Content/IconGrid/IconGrid'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ImageCarouselWithBackground',
        import: () => import('@stories/Widgets/Content/ImageCarouselWithBackground/ImageCarouselWithBackground'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'HighEnergySplitContent',
        import: () => import('@stories/Widgets/Content/HighEnergySplitContent/HighEnergySplitContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SupportSignposts',
        import: () => import('@stories/Widgets/Content/SupportSignposts/SupportSignposts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LogoText',
        import: () => import('@stories/Widgets/Content/LogoText/LogoText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductAccessoriesPicker',
        import: () => import('@stories/Widgets/Products/ProductAccessoriesPicker/ProductAccessoriesPicker'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PressureWashersComparison',
        import: () => import('@stories/Widgets/Products/PressureWashersComparison/PressureWashersComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MutableSplitSkewBanner',
        import: () => import('@stories/Widgets/Banners/MutableSplitSkewBanner/MutableSplitSkewBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LightingProductFinder',
        import: () => import('@stories/Widgets/Content/LightingProductFinder/LightingProductFinder'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TabbedProductFocus',
        import: () => import(/* webpackChunkName: "TabbedProductFocus" */ '@stories/Widgets/Content/TabbedProductFocus/TabbedProductFocus'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductComparisonTable',
        import: () => import(/* webpackChunkName: "ProductComparisonTable" */ '@stories/Widgets/Products/ProductComparisonTable/ProductComparisonTable'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TeesterTracking',
        import: () => import(/* webpackChunkName: "TeesterTracking" */ '@stories/Widgets/Misc/TeesterTracking/TeesterTracking'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'ImageWithWaves',
        import: () => import(/* webpackChunkName: "ImageWithWaves" */ '@stories/Widgets/Media/ImageWithWaves/ImageWithWaves'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'InstrumentsGallery',
        import: () => import(/* webpackChunkName: "InstrumentsGallery" */ '@stories/Widgets/Content/Instruments/InstrumentsGallery/InstrumentsGallery'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Laser360Information',
        import: () => import(/* webpackChunkName: "Laser360Information" */ '@stories/Widgets/Content/Instruments/Laser360Information/Laser360Information'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FeaturedPinnedImage',
        import: () => import(/* webpackChunkName: "FeaturedPinnedImage" */ '@stories/Widgets/Content/Instruments/FeaturedPinnedImage/FeaturedPinnedImage'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'WarrantyBanner',
        import: () => import(/* webpackChunkName: "WarrantyBanner" */ '@stories/Widgets/Banners/WarrantyBanner/WarrantyBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BuyersGuide',
        import: () => import(/* webpackChunkName: "BuyersGuides" */ '@stories/Widgets/Content/BuyersGuides/BuyersGuides'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AspectRatioBanner',
        import: () => import(/* webpackChunkName: "BuyersGuides" */ '@stories/Widgets/Banners/AspectRatioBanner/AspectRatioBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'StoreLocator',
        import: () => import(/* webpackChunkName: "StoreLocator" */ '@stories/Widgets/Content/StoreLocator/StoreLocatorRoot'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TelescopicPoleFeatures',
        import: () => import(/* webpackChunkName: "TelescopicPoleFeatures" */ '@stories/Widgets/Content/Instruments/TelescopicPoleFeatures/TelescopicPoleFeatures'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'NewsletterPopup',
        import: () => import(/* webpackChunkName: "NewsletterPopup" */ '@stories/Widgets/Forms/NewsletterPopup/NewsletterPopup'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'InstrumentsStickyNavigation',
        import: () => import(
        /* webpackChunkName: "InstrumentsStickyNavigation" */ '@stories/Widgets/Navigation/InstrumentsStickyNavigation/InstrumentsStickyNavigation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'ServiceCentreLocator',
        import: () => import(/* webpackChunkName: "ServiceCentreLocator" */ '@stories/Widgets/Content/ServiceCentreLocator/ServiceCentreLocatorRoot'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ServiceCentreLocatorSignpost',
        import: () => import(
        /* webpackChunkName: "ServiceCentreLocatorSignpost" */ '@stories/Widgets/Content/ServiceCentreLocatorSignpost/ServiceCentreLocatorSignpost'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboticLawnmowerAnimatedBanner',
        import: () => import(
        /* webpackChunkName: "RoboticLawnmowerAnimatedBanner" */ '@stories/Widgets/Content/RoboticLawnmowerAnimatedBanner/RoboticLawnmowerAnimatedBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboticLawnmowerButtons',
        import: () => import(/* webpackChunkName: "RoboticLawnmowerButtons" */ '@stories/Widgets/Content/RoboticLawnmowerButtons/RoboticLawnmowerButtons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboticLawnmowerFAQ',
        import: () => import(/* webpackChunkName: "RoboticLawnmowerFAQ" */ '@stories/Widgets/Content/RoboticLawnmowerFAQ/RoboticLawnmowerFAQ'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboticLawnmowerContactUs',
        import: () => import(/* webpackChunkName: "RoboticLawnmowerContactUs" */ '@stories/Widgets/Content/RoboticLawnmowerContactUs/RoboticLawnmowerContactUs'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PowerWithoutPetrolIcons',
        import: () => import(/* webpackChunkName: "PowerWithoutPetrolIcons" */ '@stories/Widgets/Content/PowerWithoutPetrolIcons/PowerWithoutPetrolIcons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CategoryCarousel',
        import: () => import(/* webpackChunkName: "CategoryCarousel" */ '@stories/Widgets/Media/CategoryCarousel/CategoryCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MaxPowerCallouts',
        import: () => import(/* webpackChunkName: "MaxPowerCallouts" */ '@stories/Widgets/Content/MaxPowerCallouts/MaxPowerCallouts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'GenericPopup',
        import: () => import(/* webpackChunkName: "GenericPopup" */ '@stories/Widgets/Media/GenericPopup/GenericPopup'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'VideoLibrary',
        import: () => import(/* webpackChunkName: "VideoLibrary" */ '@stories/Widgets/Media/VideoLibrary/VideoLibrary'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'NewsDetail',
        import: () => import(/* webpackChunkName: "NewsDetail" */ '@stories/Widgets/Content/Blog/NewsDetail/NewsDetail'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PowerWithoutPetrolBanner',
        import: () => import(/* webpackChunkName: "PowerWithoutPetrolBanner" */ '@stories/Widgets/Banners/PowerWithoutPetrolBanner/PowerWithoutPetrolBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MaxPowerPowerWithoutPetrolComparisonTable',
        import: () => import(
        /* webpackChunkName: "MaxPowerPowerWithoutPetrolComparisonTable" */ '@stories/Widgets/Content/MaxPowerPowerWithoutPetrolComparisonTable/MaxPowerPowerWithoutPetrolComparisonTable'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductFocusFeatures',
        import: () => import(/* webpackChunkName: "ProductFocusFeatures" */ '@stories/Widgets/Products/ProductFocusFeatures/ProductFocusFeatures'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductStickVacIcons',
        import: () => import(/* webpackChunkName: "ProductStickVacIcons" */ '@stories/Widgets/Products/ProductStickVacIcons/ProductStickVacIcons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LawnMowerCalculatorRoot',
        import: () => import(/* webpackChunkName: "LawnMowerCalculatorRoot" */ '@stories/Widgets/Products/LawnMowerCalculator/LawnMowerCalculatorRoot'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductRuntimeGraph',
        import: () => import(/* webpackChunkName: "ProductRuntimeGraph" */ '@stories/Widgets/Products/ProductRuntimeGraph/ProductRuntimeGraph'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'HowTo5050',
        import: () => import(/* webpackChunkName: "HowTo5050" */ '@stories/Widgets/Content/HowTo5050/HowTo5050'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductSignposts',
        import: () => import(/* webpackChunkName: "ProductSignposts" */ '@stories/Widgets/Products/ProductSignposts/ProductSignposts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CategorySignposts',
        import: () => import(/* webpackChunkName: "CategorySignposts" */ '@stories/Widgets/Content/CategorySignposts/CategorySignposts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BannerWithProducts',
        import: () => import(/* webpackChunkName: "BannerWithProducts" */ '@stories/Widgets/Banners/BannerWithProducts/BannerWithProducts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'BannerWithAnimation',
        import: () => import(/* webpackChunkName: "BannerWithAnimation" */ '@stories/Widgets/Banners/BannerWithAnimation/BannerWithAnimation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'TabbedProductCards',
        import: () => import(/* webpackChunkName: "TabbedProductCards" */ '@stories/Widgets/Content/TabbedProductCards/TabbedProductCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'ProductAttributeComparison',
        import: () => import(/* webpackChunkName: "ProductAttributeComparison" */ '@stories/Widgets/Content/ProductAttributeComparison/ProductAttributeComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'FullWidthVideoWithCaption',
        import: () => import(/* webpackChunkName: "FullWidthVideoWithCaption" */ '@stories/Widgets/Media/FullWidthVideoWithCaption/FullWidthVideoWithCaption'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'SplitContent',
        import: () => import(/* webpackChunkName: "SplitContent" */ '@stories/Widgets/Content/SplitContent/SplitContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'TwoColumnComparison',
        import: () => import(/* webpackChunkName: "TwoColumnComparison" */ '@stories/Widgets/Content/TwoColumnComparison/TwoColumnComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'StoreLocatorIcons',
        import: () => import(/* webpackChunkName: "StoreLocatorIcons" */ '@stories/Widgets/Media/StoreLocatorIcons/StoreLocatorIcons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AccessoryFinderRoot',
        import: () => import(/* webpackChunkName: "AccessoryFinderRoot" */ '@stories/Widgets/Products/AccessoryFinder/AccessoryFinderRoot'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductCTA',
        import: () => import(/* webpackChunkName: "ProductCTA" */ '@stories/Widgets/Products/ProductCTA/ProductCTA'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ScarifierCalendar',
        import: () => import(/* webpackChunkName: "ScarifierCalendar" */ '@stories/Widgets/Content/ScarifierCalendar/ScarifierCalendar'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ScarifierThreeColumnComparison',
        import: () => import(
        /* webpackChunkName: "ScarifierThreeColumnComparison" */ '@stories/Widgets/Media/ScarifierThreeColumnComparison/ScarifierThreeColumnComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'WhatsIncluded',
        import: () => import(/* webpackChunkName: "WhatsIncluded" */ '@stories/Widgets/Media/WhatsIncluded/WhatsIncluded'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ScarifierTopTipsImage',
        import: () => import(/* ScarifierTopTipsImage: "WhatsIncluded" */ '@stories/Widgets/Media/ScarifierTopTipsImage/ScarifierTopTipsImage'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ImageWithMobileControls',
        import: () => import(/* webpackChunkName: "ImageWithMobileControls" */ '@stories/Widgets/Media/ImageWithMobileControls/ImageWithMobileControls'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PageLanguageSelector',
        import: () => import(/* webpackChunkName: "PageLanguageSelector" */ '@stories/Widgets/Content/PageLanguageSelector/PageLanguageSelector'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BatteriesProductFilter',
        import: () => import(/* webpackChunkName: "BatteriesProductFilter" */ '@stories/Widgets/Content/BatteriesProductFilter/BatteriesProductFilter'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'ProductListingHeader',
        import: () => import(/* webpackChunkName: "ProductListingHeader" */ '@stories/Widgets/Products/ProductListingHeader/ProductListingHeader'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'ProductDetailPopUp',
        import: () => import(/* webpackChunkName: "ProductDetailPopUp" */ '@stories/Widgets/Products/ProductDetailPopUp/ProductDetailPopUp'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'WheelSectionDivider',
        import: () => import(/* webpackChunkName: "WheelSectionDivider" */ '@stories/Widgets/Misc/WheelSectionDivider/WheelSectionDivider'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RoboyagiNavigation',
        import: () => import(/* webpackChunkName: "RoboyagiNavigation" */ '@stories/Widgets/Navigation/RoboyagiNavigation/RoboyagiNavigation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'HtmlContent',
        import: () => import(/* webpackChunkName: "HtmlContent" */ '@stories/Widgets/Content/HtmlContent/HtmlContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SocialStats',
        import: () => import(/* webpackChunkName: "SocialStats" */ '@stories/Widgets/Content/SocialStats/SocialStats'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductSelector',
        import: () => import(/* webpackChunkName: "ProductSelector" */ '@stories/Widgets/Products/ProductSelector/ProductSelector'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MeetingUserNeeds',
        import: () => import(/* webpackChunkName: "MeetingUserNeeds" */ '@stories/Widgets/Content/MeetingUserNeeds/MeetingUserNeeds'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VideoSeekerCarousel',
        import: () => import(/* webpackChunkName: "VideoSeekerCarousel" */ '@stories/Widgets/Media/VideoSeekerCarousel/VideoSeekerCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerTOTY',
        import: () => import(/* webpackChunkName: "AuthorisedRetailerTOTY" */ '@stories/Widgets/Content/AuthorisedRetailerTOTY/AuthorisedRetailerTOTY'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AnchorButtons',
        import: () => import(/* webpackChunkName: "AnchorButtons" */ '@stories/Widgets/Navigation/AnchorButtons/AnchorButtons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'WebGainsCookie',
        import: () => import(/* webpackChunkName: "WebGainsCookie" */ '@stories/Widgets/Misc/WebGainsCookie/WebGainsCookie'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'OnePlusHPNewTool',
        import: () => import(/* webpackChunkName: "OnePlusHPNewTool" */ '@stories/Widgets/Content/OnePlusHPNewTool/OnePlusHPNewTool'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'GroupedFeatureCards',
        import: () => import(/* webpackChunkName: "GroupedFeatureCards" */ '@stories/Widgets/Content/GroupedFeatureCards/GroupedFeatureCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'StickVacRuntimeComparison',
        import: () => import(/* webpackChunkName: "StickVacRuntimeComparison" */ '@stories/Widgets/Content/StickVacRuntimeComparison/StickVacRuntimeComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductWithFeatures',
        import: () => import(/* webpackChunkName: "ProductWithFeatures" */ '@stories/Widgets/Content/ProductWithFeatures/ProductWithFeatures'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'StickVacProductComparison',
        import: () => import(/* webpackChunkName: "StickVacProductComparison" */ '@stories/Widgets/Products/StickVacProductComparison/StickVacProductComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BannerWithCaption',
        import: () => import(/* webpackChunkName: "BannerWithCaption" */ '@stories/Widgets/Content/BannerWithCaption/BannerWithCaption'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SplitCategoryBanner',
        import: () => import(/* webpackChunkName: "SplitCategoryBanner" */ '@stories/Widgets/Banners/SplitCategoryBanner/SplitCategoryBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'SnowThrowerComparison',
        import: () => import(/* webpackChunkName: "SnowThrowerComparison" */ '@stories/Widgets/Products/SnowThrowerComparison/SnowThrowerComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SoundComparison',
        import: () => import(/* webpackChunkName: "SoundComparison" */ '@stories/Widgets/Media/SoundComparison/SoundComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SideTabbedHotspots',
        import: () => import(/* webpackChunkName: "SideTabbedHotspots" */ '@stories/Widgets/Media/SideTabbedHotspots/SideTabbedHotspots'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SplitInnerBanner',
        import: () => import(/* webpackChunkName: "SplitInnerBanner" */ '@stories/Widgets/Banners/SplitInnerBanner/SplitInnerBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TeesterVSG',
        import: () => import(/* webpackChunkName: "TeesterVSG" */ '@stories/Widgets/Media/TeesterVSG/TeesterVSG'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'NumericSignpostCards',
        import: () => import(/* webpackChunkName: "NumericSignpostCards" */ '@stories/Widgets/Content/NumericSignpostCards/NumericSignpostCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AuthorisedRetailerSignInSignUpForm',
        import: () => import(
        /* webpackChunkName: "AuthorisedRetailerSignInSignUpForm" */ '@stories/Widgets/Forms/AuthorisedRetailer/AuthorisedRetailerSignInSignUpForm/AuthorisedRetailerSignInSignUpForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VideoTextAnimation',
        import: () => import(/* webpackChunkName: "VideoTextAnimation" */ '@stories/Widgets/Content/RideOn/VideoTextAnimation/VideoTextAnimation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RideOnComparison',
        import: () => import(/* webpackChunkName: "RideOnComparison" */ '@stories/Widgets/Content/RideOn/RideOnComparison/RideOnComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SplitSkewBannerWithIcons',
        import: () => import(/* webpackChunkName: "SplitSkewBannerWithIcons" */ '@stories/Widgets/Content/RideOn/SplitSkewBannerWithIcons/SplitSkewBannerWithIcons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CalloutText5050',
        import: () => import(/* webpackChunkName: "CalloutText5050" */ '@stories/Widgets/Content/RideOn/SplitSkewBannerWithIcons/CalloutText5050/CalloutText5050'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TrustpilotFeed',
        import: () => import(/* webpackChunkName: "TrustpilotFeed" */ '@stories/Components/Products/TrustpilotFeed/TrustpilotFeed'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RotaryMandrelGuide',
        import: () => import(/* webpackChunkName: "RotaryMandrelGuide" */ '@stories/Widgets/Products/RotaryMandrelGuide/RotaryMandrelGuide'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RotaryUniversalFit',
        import: () => import(/* webpackChunkName: "RotaryUniversalFit" */ '@stories/Widgets/Products/RotaryUniversalFit/RotaryUniversalFit'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RotaryAccessories',
        import: () => import(/* webpackChunkName: "RotaryAccessories" */ '@stories/Widgets/Content/RotaryAccessories/RotaryAccessories'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AnimatedProductCards',
        import: () => import(/* webpackChunkName: "AnimatedProductCards" */ '@stories/Widgets/Content/RideOn/AnimatedProductCards/AnimatedProductCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'EcommerceCarousel',
        import: () => import(/* webpackChunkName: "EcommerceCarousel" */ '@stories/Widgets/Media/EcommerceCarousel/EcommerceCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AdvancedHotspotBanner',
        import: () => import(/* webpackChunkName: "AdvancedHotspotBanner" */ '@stories/Widgets/Media/AdvancedHotspotBanner/AdvancedHotspotBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductCarouselWithOverview',
        import: () => import(
        /* webpackChunkName: "ProductCarouselWithOverview" */ '@stories/Widgets/Content/ProductCarouselWithOverview/ProductCarouselWithOverview'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'StepByStepMedia',
        import: () => import(/* webpackChunkName: "StepByStepMedia" */ '@stories/Widgets/Content/StepByStepMedia/StepByStepMedia'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CordlessChainsawIcons',
        import: () => import(/* webpackChunkName: "CordlessChainsawIcons" */ '@stories/Widgets/Content/CordlessChainsawIcons/CordlessChainsawIcons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ChainsawBarSize',
        import: () => import(/* webpackChunkName: "ChainsawBarSize" */ '@stories/Widgets/Content/ChainsawBarSize/ChainsawBarSize'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CentreTextWithImages',
        import: () => import(/* webpackChunkName: "CentreTextWithImages" */ '@stories/Widgets/Content/CentreTextWithImages/CentreTextWithImages'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VariableWidthCarousel',
        import: () => import(/* webpackChunkName: "VariableWidthCarousel" */ '@stories/Widgets/Content/VariableWidthCarousel/VariableWidthCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PageScrollBar',
        import: () => import(/* webpackChunkName: "PageScrollBar" */ '@stories/Widgets/Content/RideOn/PageScrollBar/PageScrollBar'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Authenticator',
        import: () => import(/* webpackChunkName: "Authenticator" */ '@stories/Widgets/MyAccount/Authenticator/Authenticator'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Dashboard',
        import: () => import(/* webpackChunkName: "Dashboard" */ '@stories/Widgets/MyAccount/Dashboard/Dashboard'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ForgotPassword',
        import: () => import(/* webpackChunkName: "ForgotPassword" */ '@stories/Widgets/MyAccount/ForgotPassword/ForgotPassword'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'EditProfile',
        import: () => import(/* webpackChunkName: "EditProfile" */ '@stories/Widgets/MyAccount/EditProfile/EditProfile'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MyReviews',
        import: () => import(/* webpackChunkName: "MyReviews" */ '@stories/Widgets/MyAccount/MyReviews/MyReviews'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MySupport',
        import: () => import(/* webpackChunkName: "MySupport" */ '@stories/Widgets/MyAccount/MySupport/MySupport'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OrderHistory',
        import: () => import(/* webpackChunkName: "OrderHistory" */ '@stories/Widgets/MyAccount/OrderHistory/OrderHistory'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OrderHistoryDetail',
        import: () => import(/* webpackChunkName: "OrderHistoryDetail" */ '@stories/Widgets/MyAccount/OrderHistoryDetail/OrderHistoryDetail'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BrandTimelineCarousel',
        import: () => import(/* webpackChunkName: "BrandTimelineCarousel" */ '@stories/Widgets/Content/BrandTimelineCarousel/BrandTimelineCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'ResetPassword',
        import: () => import(/* webpackChunkName: "ResetPassword" */ '@stories/Widgets/MyAccount/ResetPassword/ResetPassword'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'RotarySignposts',
        import: () => import(/* webpackChunkName: "RotarySignposts" */ '@stories/Widgets/Products/RotarySignposts/RotarySignposts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'NotifyModal',
        import: () => import('@stories/Widgets/Products/NotifyModal/NotifyModal'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'OnePlusTechnologyBanner',
        import: () => import(/* webpackChunkName: "OnePlusTechnologyBanner" */ '@stories/Widgets/Banners/OnePlusTechnologyBanner/OnePlusTechnologyBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CategoryCardsCarousel',
        import: () => import(/* webpackChunkName: "CategoryCardsCarousel" */ '@stories/Widgets/Content/CategoryCardsCarousel/CategoryCardsCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RotatingProductCarousel',
        import: () => import(/* webpackChunkName: "RotatingProductCarousel" */ '@stories/Widgets/Content/RotatingProductCarousel/RotatingProductCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LinkIconGrid',
        import: () => import(/* webpackChunkName: "LinkIconGrid" */ '@stories/Widgets/Content/LinkIconGrid/LinkIconGrid'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Link5050SplitContent',
        import: () => import(/* webpackChunkName: "Link5050SplitContent" */ '@stories/Widgets/Content/Link5050SplitContent/Link5050SplitContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LinkSteppedContent',
        import: () => import(/* webpackChunkName: "LinkSteppedContent" */ '@stories/Widgets/Content/LinkSteppedContent/LinkSteppedContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LinkTextDivider',
        import: () => import(/* webpackChunkName: "LinkTextDivider" */ '@stories/Widgets/Content/LinkTextDivider/LinkTextDivider'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LinkSignpost',
        import: () => import(/* webpackChunkName: "LinkSignpost" */ '@stories/Widgets/Products/LinkSignpost/LinkSignpost'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FullWidthBannerWithCentralText',
        import: () => import(
        /* webpackChunkName: "FullWidthBannerWithCentralText" */ '@stories/Widgets/Banners/FullWidthBannerWithCentralText/FullWidthBannerWithCentralText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FullWidthImageCarousel',
        import: () => import(/* webpackChunkName: "FullWidthImageCarousel" */ '@stories/Widgets/Media/FullWidthImageCarousel/FullWidthImageCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LinkWallRailHotspot',
        import: () => import(/* webpackChunkName: "LinkWallRailHotspot" */ '@stories/Widgets/Content/LinkWallRailHotspot/LinkWallRailHotspot'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LinkAmbientHotspotCarousel',
        import: () => import(/* webpackChunkName: "LinkAmbientHotspotCarousel" */ '@stories/Widgets/Content/LinkAmbientHotspotCarousel/LinkAmbientHotspotCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LinkNavigation',
        import: () => import(/* webpackChunkName: "LinkNavigation" */ '@stories/Widgets/Navigation/LinkNavigation/LinkNavigation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LabelledApplicationCards',
        import: () => import(/* webpackChunkName: "LabelledApplicationCards" */ '@stories/Widgets/Content/LabelledApplicationCards/LabelledApplicationCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PrimarySecondaryProducts',
        import: () => import(/* webpackChunkName: "PrimarySecondaryProducts" */ '@stories/Widgets/Content/PrimarySecondaryProducts/PrimarySecondaryProducts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SwiftCleanBanner',
        import: () => import(/* webpackChunkName: "SwiftCleanBanner" */ '@stories/Widgets/Banners/SwiftCleanBanner/SwiftCleanBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'USBLithiumBanner',
        import: () => import(/* webpackChunkName: "USBLithiumBanner" */ '@stories/Widgets/Media/USBLithiumBanner/USBLithiumBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ScrollingSteps',
        import: () => import(/* webpackChunkName: "ScrollingSteps" */ '@stories/Widgets/Content/ScrollingSteps/ScrollingSteps'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ThemedSplitContent',
        import: () => import(/* webpackChunkName: "ThemedSplitContent" */ '@stories/Widgets/Content/ThemedSplitContent/ThemedSplitContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CompleteYourAccount',
        import: () => import(/* webpackChunkName: "CompleteYourAccount" */ '@stories/Widgets/MyAccount/CompleteYourAccount/CompleteYourAccount'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'NumberedSignposts',
        import: () => import(/* webpackChunkName: "NumberedSignposts" */ '@stories/Widgets/Content/NumberedSignposts/NumberedSignposts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CordlessSystemSignpost',
        import: () => import(/* webpackChunkName: "CordlessSystemSignpost" */ '@stories/Widgets/Content/CordlessSystemSignpost/CordlessSystemSignpost'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FiftyFiftyCategorySignpost',
        import: () => import(/* webpackChunkName: "FiftyFiftyCategorySignpost" */ '@stories/Widgets/Content/FiftyFiftyCategorySignpost/FiftyFiftyCategorySignpost'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LandingPageSignpost',
        import: () => import(/* webpackChunkName: "LandingPageSignpost" */ '@stories/Widgets/Content/LandingPageSignpost/LandingPageSignpost'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PageBanner',
        import: () => import(/* webpackChunkName: "PageBanner" */ '@stories/Widgets/Banners/PageBanner/PageBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RelatedAccessories',
        import: () => import(/* webpackChunkName: "RelatedAccessories" */ '@stories/Widgets/Products/RelatedAccessories/RelatedAccessories'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PowerWasherComparison',
        import: () => import(/* webpackChunkName: "PowerWasherComparison" */ '@stories/Widgets/Content/PowerWasherComparison/PowerWasherComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'DrtvVideo',
        import: () => import(/* webpackChunkName: "DrtvVideo" */ '@stories/Widgets/ProductDetail/DrtvVideo/DrtvVideo'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: '_210x297',
        import: () => import(/* webpackChunkName: "_210x297" */ '@stories/Widgets/FactTags/210x297/210x297'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: '_210x148',
        import: () => import(/* webpackChunkName: "_210x148" */ '@stories/Widgets/FactTags/210x148/210x148'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: '_149x69',
        import: () => import(/* webpackChunkName: "_149x69" */ '@stories/Widgets/FactTags/149x69/149x69'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: '_74x105',
        import: () => import(/* webpackChunkName: "_74x105" */ '@stories/Widgets/FactTags/74x105/74x105'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: '_105x148',
        import: () => import(/* webpackChunkName: "_105x148" */ '@stories/Widgets/FactTags/105x148/105x148'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'Cover',
        import: () => import(/* webpackChunkName: "Cover" */ '@stories/Widgets/FactTags/Cover/Cover'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'AddressLabel',
        import: () => import(/* webpackChunkName: "AddressLabel" */ '@stories/Widgets/FactTags/AddressLabel/AddressLabel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OnePlusStarterKit',
        import: () => import(/* webpackChunkName: "OnePlusStarterKit" */ '@stories/Widgets/Content/OnePlusStarterKit/OnePlusStarterKit'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'CreateAccountPromo',
        import: () => import(/* webpackChunkName: "CreateAccountPromo" */ '@stories/Widgets/MyAccount/CreateAccountPromo/CreateAccountPromo'),
        config: {
            hydrate: false,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VerifyAccount',
        import: () => import(/* webpackChunkName: "VerifyAccount" */ '@stories/Widgets/MyAccount/VerifyAccount/VerifyAccount'),
        config: {
            hydrate: false,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AddressLabel',
        import: () => import(/* webpackChunkName: "AddressLabel" */ '@stories/Widgets/FactTags/AddressLabel/AddressLabel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ExtendedRegisterForm',
        import: () => import(/* webpackChunkName: "ExtendedRegisterForm" */ '@stories/Widgets/Forms/ExtendedRegisterForm/ExtendedRegisterForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RedemptionForm',
        import: () => import(/* webpackChunkName: "RedemptionForm" */ '@stories/Widgets/Forms/RedemptionForm/RedemptionForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'InteractiveHouse',
        import: () => import(/* webpackChunkName: "InteractiveHouse" */ '@stories/Widgets/Media/InteractiveHouse/InteractiveHouse'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductFinderWithCategories',
        import: () => import(
        /* webpackChunkName: "ProductFinderWithCategories" */ '@stories/Widgets/Content/ProductFinderWithCategories/ProductFinderWithCategories'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductIconBanner',
        import: () => import(/* webpackChunkName: "ProductIconBanner" */ '@stories/Widgets/ProductDetail/ProductIconBanner/ProductIconBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FeatureCallouts',
        import: () => import(/* webpackChunkName: "FeatureCallouts" */ '@stories/Widgets/ProductDetail/FeatureCallouts/FeatureCallouts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FeaturesAndSpecifications',
        import: () => import(
        /* webpackChunkName: "FeaturesAndSpecifications" */ '@stories/Widgets/ProductDetail/FeaturesAndSpecifications/FeaturesAndSpecifications'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RelatedProducts',
        import: () => import(/* webpackChunkName: "RelatedProducts" */ '@stories/Widgets/ProductDetail/RelatedProducts/RelatedProducts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RuntimeComparison',
        import: () => import(/* webpackChunkName: "RuntimeComparison" */ '@stories/Widgets/ProductDetail/RuntimeComparison/RuntimeComparison'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductContentCards',
        import: () => import(/* webpackChunkName: "ProductContentCards" */ '@stories/Widgets/ProductDetail/ProductContentCards/ProductContentCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductContentBanner',
        import: () => import(/* webpackChunkName: "ProductContentBanner" */ '@stories/Widgets/ProductDetail/ProductContentBanner/ProductContentBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductOverview',
        import: () => import(/* webpackChunkName: "ProductOverview" */ '@stories/Widgets/ProductDetail/ProductOverview/ProductOverview'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductSkeeperVideo',
        import: () => import(/* webpackChunkName: "ProductSkeeperVideo" */ '@stories/Widgets/ProductDetail/ProductSkeeperVideo/ProductSkeeperVideo'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'ProductDetailReviews',
        import: () => import(/* webpackChunkName: "ProductDetailReviews" */ '@stories/Widgets/ProductDetail/ProductReviews/ProductDetailReviews'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductDetailWarrantyBanner',
        import: () => import(/* webpackChunkName: "ProductDetailWarrantyBanner" */ '@stories/Widgets/ProductDetail/WarrantyBanner/WarrantyBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductDetailDownload',
        import: () => import(/* webpackChunkName: "ProductDetailDownload" */ '@stories/Widgets/ProductDetail/Download/Download'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CompatibleAccessories',
        import: () => import(/* webpackChunkName: "CompatibleAccessories" */ '@stories/Widgets/Content/RideOn/CompatibleAccessories/CompatibleAccessories'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FAQSearch',
        import: () => import(/* webpackChunkName: "FAQSearch" */ '@stories/Widgets/Content/FAQSearch/FAQSearch'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TwinCardCta',
        import: () => import(/* webpackChunkName: "TwinCardCta" */ '@stories/Widgets/Content/TwinCardCta/TwinCardCta'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BatteryHeroBanner',
        import: () => import(/* webpackChunkName: "BatteryHeroBanner" */ '@stories/Widgets/Banners/BatteryHeroBanner/BatteryHeroBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TripleBatteryImage',
        import: () => import(/* webpackChunkName: "TripleBatteryImage" */ '@stories/Widgets/Content/TripleBatteryImage/TripleBatteryImage'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MorePowerMoreRuntime',
        import: () => import(/* webpackChunkName: "MorePowerMoreRuntime" */ '@stories/Widgets/Content/MorePowerMoreRuntime/MorePowerMoreRuntime'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'HighEnergyProductTab',
        import: () => import(/* webpackChunkName: "HighEnergyProductTab" */ '@stories/Widgets/Content/HighEnergyProductTab/HighEnergyProductTab'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BatteryTechnologySplitContent',
        import: () => import(
        /* webpackChunkName: "BatteryTechnologySplitContent" */ '@stories/Widgets/Content/BatteryTechnologySplitContent/BatteryTechnologySplitContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SplitContentWithScrollingTabs',
        import: () => import(
        /* webpackChunkName: "SplitContentWithScrollingTabs" */ '@stories/Widgets/Content/SplitContentWithScrollingTabs/SplitContentWithScrollingTabs'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BatteryContentHighEnergy',
        import: () => import(/* webpackChunkName: "BatteryContentHighEnergy" */ '@stories/Widgets/Content/BatteryContentHighEnergy/BatteryContentHighEnergy'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BatteryIconsMaxPower',
        import: () => import(/* webpackChunkName: "BatteryIconsMaxPower" */ '@stories/Widgets/Content/BatteryIconsMaxPower/BatteryIconsMaxPower'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductComparisonMaxPower',
        import: () => import(/* webpackChunkName: "ProductComparisonMaxPower" */ '@stories/Widgets/Content/ProductComparisonMaxPower/ProductComparisonMaxPower'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ScrollspyNavigation',
        import: () => import(/* webpackChunkName: "ScrollspyNavigation" */ '@stories/Widgets/Navigation/ScrollspyNavigation/ScrollspyNavigation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RegisterToolCtaBanner',
        import: () => import(/* webpackChunkName: "RegisterToolCtaBanner" */ '@stories/Widgets/Content/RegisterToolCtaBanner/RegisterToolCtaBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ServiceIconsList',
        import: () => import(/* webpackChunkName: "ServiceIconsList" */ '@stories/Widgets/Content/ServiceIconsList/ServiceIconsList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ServiceCards',
        import: () => import(/* webpackChunkName: "ServiceCards" */ '@stories/Widgets/Content/ServiceCards/ServiceCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductSpecCards',
        import: () => import(/* webpackChunkName: "ProductSpecCards" */ '@stories/Widgets/Content/ProductSpecCards/ProductSpecCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'QuoteCallout',
        import: () => import(/* webpackChunkName: "QuoteCallout" */ '@stories/Widgets/Content/QuoteCallout/QuoteCallout'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SocialImageGallery',
        import: () => import(/* webpackChunkName: "SocialImageGallery" */ '@stories/Widgets/Content/SocialImageGallery/SocialImageGallery'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FloorcareBanner',
        import: () => import(/* webpackChunkName: "FloorcareBanner" */ '@stories/Widgets/Banners/FloorcareBanner/FloorcareBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'SwiftCleanMainBanner',
        import: () => import(/* webpackChunkName: "SwiftCleanMainBanner" */ '@stories/Widgets/Banners/SwiftCleanMainBanner/SwiftCleanMainBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FloorcareStepCards',
        import: () => import(/* webpackChunkName: "FloorcareStepCards" */ '@stories/Widgets/Content/FloorcareStepCards/FloorcareStepCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'FloorcareFeatureHighlight',
        import: () => import(/* webpackChunkName: "FloorcareFeatureHighlight" */ '@stories/Widgets/Content/FloorcareFeatureHighlight/FloorcareFeatureHighlight'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FloorcareTabbedProductFeatures',
        import: () => import(
        /* webpackChunkName: "FloorcareTabbedProductFeatures" */ '@stories/Widgets/Content/FloorcareTabbedProductFeatures/FloorcareTabbedProductFeatures'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FloorcareDetailedProductCards',
        import: () => import(
        /* webpackChunkName: "FloorcareDetailedProductCards" */ '@stories/Widgets/Products/FloorcareDetailedProductCards/FloorcareDetailedProductCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FloorcareRangeCards',
        import: () => import(/* webpackChunkName: "FloorCareRangeCards" */ '@stories/Widgets/Content/FloorcareRangeCards/FloorcareRangeCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FloorcareSimpleCards',
        import: () => import(/* webpackChunkName: "FloorCareSimpleCards" */ '@stories/Widgets/Content/FloorcareSimpleCards/FloorcareSimpleCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FloorcareSimpleProductCards',
        import: () => import(
        /* webpackChunkName: "FloorcareSimpleProductCards" */ '@stories/Widgets/Products/FloorcareSimpleProductCards/FloorcareSimpleProductCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FloorcareImageAndText',
        import: () => import(/* webpackChunkName: "FloorcareImageAndText" */ '@stories/Widgets/Content/FloorcareImageAndText/FloorcareImageAndText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FloorcareThreeColumn',
        import: () => import(/* webpackChunkName: "FloorCareThreeColumn" */ '@stories/Widgets/Content/FloorcareThreeColumn/FloorcareThreeColumn'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MediaCardCarousel',
        import: () => import(/* webpackChunkName: "MediaCardCarousel" */ '@stories/Widgets/Content/MediaCardCarousel/MediaCardCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TabbedFeatureCards',
        import: () => import(/* webpackChunkName: "TabbedFeatureCards" */ '@stories/Widgets/Content/TabbedFeatureCards/TabbedFeatureCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductGiftCard',
        import: () => import(/* webpackChunkName: "ProductGiftCard" */ '@stories/Widgets/ProductDetail/ProductGiftCard/ProductGiftCard'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BalanceCheck',
        import: () => import(/* webpackChunkName: "BalanceCheck" */ '@stories/Widgets/Content/BalanceCheck/BalanceCheck'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FramingNailerIntroduction',
        import: () => import(/* webpackChunkName: "FramingNailerIntroduction" */ '@stories/Widgets/Banners/FramingNailerIntroduction/FramingNailerIntroduction'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'DualCallouts',
        import: () => import(/* webpackChunkName: "DualCallouts" */ '@stories/Widgets/Content/DualCallouts/DualCallouts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VideoDiagonalCallout',
        import: () => import(/* webpackChunkName: "VideoDiagonalCallout" */ '@stories/Widgets/Media/VideoDiagonalCallout/VideoDiagonalCallout'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'GiftCardRichText',
        import: () => import(/* webpackChunkName: "GiftCardRichText" */ '@stories/Widgets/Content/GiftCardRichText/GiftCardRichText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'MailingOptOut',
        import: () => import(/* webpackChunkName: "MailingOptOut" */ '@stories/Widgets/Forms/MailingOptOut/MailingOptOut'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AutomatedEmailUnsubscription',
        import: () => import(
        /* webpackChunkName: "AutomatedEmailUnsubscription" */ '@stories/Widgets/Content/AutomatedEmailUnsubscription/AutomatedEmailUnsubscription'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'ProductStickyNav',
        import: () => import(/* webpackChunkName: "ProductStickyNav" */ '@stories/Widgets/ProductDetail/ProductStickyNav/ProductStickyNav'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'BasketWithLogin',
        import: () => import(/* webpackChunkName: "BasketWithLogin" */ '@stories/Widgets/Ecommerce/BasketWithLogin/BasketWithLogin'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'RegisterFeedbackForm',
        import: () => import(/* webpackChunkName: "RegisterFeedbackForm" */ '@stories/Widgets/Forms/RegisterFeedbackForm/RegisterFeedbackForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CategoryCards',
        import: () => import(/* webpackChunkName: "CategoryCards" */ '@stories/Widgets/Content/CategoryCards/CategoryCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SeoContent',
        import: () => import(/* webpackChunkName: "SeoContent" */ '@stories/Widgets/ProductDetail/SeoContent/SeoContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BackToTop',
        import: () => import(/* webpackChunkName: "BackToTop" */ '@stories/Widgets/ProductDetail/BackToTop/BackToTop'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CookiePopup',
        import: () => import(/* webpackChunkName: "CookiePopup" */ '@stories/Widgets/Misc/CookiePopup/CookiePopup'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
            blockedByOnetrust: false,
        },
    },
    {
        name: 'DataLayerTracking',
        import: () => import(/* webpackChunkName: "DataLayerTracking" */ '@stories/Widgets/Misc/DataLayerTracking/DataLayerTracking'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
];
